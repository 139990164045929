/**
 * IntersectionObserver based Scroll Depth
 */

function scrollDepthTracker() {
  let depths = [0.25, 0.5, 0.75, 1];

  let observerCallback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        let label = 'Scroll Depth ' + parseFloat(entry.target.dataset.depth) * 100 + '%';
        observer.unobserve(entry.target);

        if (typeof gtag !== 'undefined') {
          gtag('event', 'Scroll Depth', {
            'event_category': 'Scroll Depth Percentage',
            'event_label': label,
            'non_interaction': true
          });
        }

        if (window.umami && umami.track) {
          umami.track(label);
        }
      }
    });
  };

  let observer = new IntersectionObserver(observerCallback);

  for (let depth of depths) {
    let marker = document.createElement("i");

    marker.style.position = 'absolute';
    marker.style.top = 100 * depth + '%';
    marker.style.marginTop = '-1px';
    marker.style.zIndex = '-1';
    marker.dataset.depth = depth;

    document.body.appendChild(marker);
    observer.observe(marker);
  }
}

let script = document.querySelector('[src="https://a7s2.iop.dev/script.js"]');
if(script) {
  script.addEventListener('load', scrollDepthTracker);
}
